const extractFrequencyName = (dataValue, dataArray, val) => {
  const matchingItem = dataArray?.find(
    (item) => item.frequency_id === dataValue
  );
  if (matchingItem) {
    if (val === 'name') {
      return matchingItem.frequency_name;
    }
    if (matchingItem.frequency_type === 'custom') {
      return matchingItem.sub_frequency_type;
    }
    return matchingItem.frequency_type;
  }
  return null;
};

export default extractFrequencyName;
