// Project Imports
import apiEndpoint from 'core/common/apiEndPoints';
import axiosInstance from 'providers/axiosInstance/axiosInstance';
import useLogger from 'core/utils/useLogger';

const fetchOrganization = async (
  setOrganizationList: React.Dispatch<React.SetStateAction<any[]>>
): Promise<void> => {
  try {
    const response = await axiosInstance.get(
      `${process.env.REACT_APP_API_URL_ORG}/${apiEndpoint.organization}/?skip_user_permission=true&page=1&page_size=100`
    );
    const responseData = (response.data as any)?.results;
    const data = responseData.map((item) => ({
      label: item.organization_name,
      value: item.unique_id,
    }));
    await setOrganizationList(data);
  } catch (error) {
    if (error?.response?.data) {
      const logger = useLogger();
      logger('Organization list error :: ', error?.response?.data);
    }
  }
};

export default fetchOrganization;
