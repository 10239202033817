// Project imports

import getDateValue from 'core/utils/getDateValue';
import getWeeklyDateValue from 'core/utils/getWeeklyDateValue';
import getDateInterval from 'core/utils/getDateInterval';
import getYearDate from 'core/utils/getYearDate';

export default function getFilteFormattedDate(frequency, date, timelineType) {
  // timelineType select(true)

  switch (frequency) {
    case 'daily':
      return getDateValue(date);

    case 'weekly':
      return timelineType ? date : getWeeklyDateValue(date);

    case 'monthly':
      return getDateInterval(date);

    case 'quarterly':
      return timelineType ? date : getDateInterval(date);
    case 'half yearly':
      return timelineType ? date : getDateInterval(date);

    case 'yearly':
      return getYearDate(date);

    default:
      return null;
  }
}
