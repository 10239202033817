// Package Imports
import React, { useState, useEffect, useRef } from 'react'
import { Form, Modal, Select, Upload, message } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
// Project Imports
import A8Utils from '../../../utils/A8Utils';
import TextArea from 'antd/es/input/TextArea';
import Dragger from 'antd/es/upload/Dragger';

const DangerButtonModal = (props) => {
  const { value, component, showNegativeModal, setShowNegativeModal, modalSubmitHandler } = props;

  const [fileList, setFileList] = useState([])
  const [reasonOptionList, setReasonOptionList] = useState([])
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const textAreaRef = useRef(null);

  const FormItem = Form.Item;
  const [form] = Form.useForm();
  const fileExtensions = [A8Utils.fileExtensions.csv, A8Utils.fileExtensions.xls, A8Utils.fileExtensions.xlsx, A8Utils.fileExtensions.dmn, A8Utils.fileExtensions.bpmn]
  const fileTypes = [A8Utils.fileTypes.csv, A8Utils.fileTypes.xls, A8Utils.fileTypes.xlsx, A8Utils.fileTypes.dmn, A8Utils.fileTypes.bpmn]

  function handleOk() {
    form.validateFields()
      .then((values) => {
        setShowNegativeModal(false)
        modalSubmitHandler(values, fileList[0])
        resetForm()
      })
      .catch((errorInfo) => { });
  }

  function resetForm() {
    setShowNegativeModal(false)
    setFileList([])
    form.resetFields()
  }

  function onSearch(value) {
  };

  function onChange(value) {
  };

  function normFile(e) {
    return e.fileList[0];
  };

  function getReasonApiCallHandler() {
    let apiUrl = component.reasonsApiUrl;
    let apiUrlParams = {}

    component?.reasonsApiUrlParamsList?.forEach((item, index) => {
      switch (item.reasonsApiUrlTarget) {
        case A8Utils.formBuilderFieldNames.submission:
          apiUrlParams[item.reasonsApiUrlParameter] = A8Utils.getColumnElementValue(value, item.reasonsApiUrlTargetKey)
          break;

        default:
          break;
      }
    })
    apiUrl = A8Utils.getAPiUrl(apiUrl, apiUrlParams)

    const token = localStorage.getItem('token');
    const tokenType = localStorage.getItem('tokenType')
    const modifiedConfig = {
      headers: {
        'Content-Type': 'application/json',
        ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
      },
    };

    fetch(apiUrl, {
      headers: modifiedConfig?.headers,
    })
      .then((res) => res.json())
      .then((json) => {
        handleReasonOptionList(json)
      })
      .catch((error) => {
      })
  }

  function handleReasonOptionList(data) {
    const list = A8Utils.getColumnElementValue(data, component?.reasonsApiUrlResponseDataKey, "object")
    const modifiedList = list.map(item => ({
      ...item,
      label: item.reason,
      value: item.reason
    }));
    setReasonOptionList(modifiedList)
  }

  const uploadComponentProps = {
    multiple: false,
    maxCount: 1,
    showUploadList: true,
    onRemove: (file) => {
      setFileList([])
    },
    beforeUpload: (file) => {
      let fileNameArray = file.name.split(".")
      let fileExtension = fileNameArray[fileNameArray.length-1]
      if (!fileExtensions.includes(fileExtension)) {
        message.error(A8Utils.messages.rejectButtonFileFormatError);
        return Upload.LIST_IGNORE;
      } else {
        if (fileList.length < 2) {
          setFileList([file]);
          return false;
        }
      }
    },
    fileList
  };

  useEffect(() => {
    getReasonApiCallHandler()
  }, [])

  useEffect(() => {
      if (showNegativeModal && textAreaRef.current) {
      setTimeout(() => {
        if (textAreaRef.current) {
          textAreaRef.current.focus();
        }
      }, 0);
    }
  }, [showNegativeModal]);

  return (
    <>
      <Modal title="Reject" okText="Submit" open={showNegativeModal} onOk={handleOk} onCancel={resetForm} okButtonProps={{ disabled: buttonDisabled }}>
        <Form
          form={form}
          onFieldsChange={() => {
            let isFormRemaining = !form.isFieldsTouched(true) || form.getFieldsError().some((field) => field.errors.length > 0)
            setButtonDisabled(isFormRemaining)
          }}
        >
          {
            component?.negativeActionButtonComponentsList?.map((item, index) => {
              switch (item.componentFieldType) {
                case A8Utils.formBuilderFieldNames.select:
                  return (
                    <>
                      <div key={index} className=''>
                        <label className="">{item.componentLabel} <span className='asterisk'>*</span></label>
                        <FormItem
                          name={item.compoentFormNameParameter}
                          rules={[
                            { required: true, message: A8Utils.labels.reasonValidation },
                          ]}
                        >
                          <Select
                            className=''
                            size="large"
                            placeholder={A8Utils.labels.reasonPlaceholder}
                            onChange={onChange}
                            onSearch={onSearch}
                            optionFilterProp="label"
                            showSearch
                            filterOption
                            options={reasonOptionList}
                          />
                        </FormItem>
                      </div>
                    </>
                  )
                case A8Utils.formBuilderFieldNames.text:
                  return (
                    <>
                      <div key={index} className=''>
                        <label className="">{item.componentLabel} <span className='asterisk'>*</span></label>
                        <FormItem
                          name={item.compoentFormNameParameter}
                          rules={[
                            { required: true, message: A8Utils.labels.commentsValidation },
                          ]}
                        >
                          <TextArea
                            ref={textAreaRef}
                            rows={4}
                            placeholder={`${A8Utils.labels.enter} ${item.componentLabel}`}
                            maxLength={200}
                          />
                        </FormItem>
                      </div>
                    </>
                  )
                case A8Utils.formBuilderFieldNames.upload:
                  return (
                    <>
                      <div key={index} className=''>
                        <label >{item.componentLabel} <span className='asterisk'>*</span></label>
                        <FormItem
                          name={item.compoentFormNameParameter}
                          rules={[
                            { required: true, message: A8Utils.labels.referenceFileValidation }
                          ]}
                          getValueFromEvent={normFile}
                        >
                          <Dragger
                            {...uploadComponentProps}
                          >
                            <p className="ant-upload-drag-icon">
                              <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{A8Utils.labels.uploadComponentTitle}</p>
                            <p className="ant-upload-hint">
                              {A8Utils.labels.uploadComponentSubTitle} - {fileExtensions.map((item, key) => {
                                return (
                                  <>
                                    {item}
                                    {fileExtensions.length - 1 !== key ? ", " : ""}
                                  </>
                                )
                              })}
                            </p>
                          </Dragger>
                        </FormItem>
                      </div>
                    </>
                  )
              }
            })
          }
        </Form>
      </Modal>
    </>
  )
}

export default DangerButtonModal