// package imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// project imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const SimulationOverviewParent = lazy(
  () => import('pages/simulation/viewSimulation/simulationOverviewParent')
);
const SimulationTable = lazy(
  () => import('pages/simulation/viewSimulation/simulationTable')
);
const AccountDetails = lazy(
  () => import('pages/simulation/viewSimulation/accountDetails')
);
const ConfigureSimulation = lazy(
  () => import('pages/simulation/viewSimulation/configureSimulation')
);
const ViewSimulationRoute = (
  <Route path={routeName.viewSimulation}>
    <Route
      index
      element={
        <PrivateRoute name={[PrivateRouteConst.VIEW_SIMULATION]}>
          <Suspense fallback={<Loading />}>
            <SimulationTable />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        <PrivateRoute name={[PrivateRouteConst.VIEW_SIMULATION]}>
          <Suspense fallback={<Loading />}>
            <SimulationOverviewParent />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="configure_simulation/account/:id"
      element={
        <PrivateRoute name={[PrivateRouteConst.VIEW_SIMULATION]}>
          <Suspense fallback={<Loading />}>
            <ConfigureSimulation />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path="show/account/:simulationId/:id"
      element={
        // <PrivateRoute name={[PrivateRouteConst.VIEW_SIMULATION]}>
        <Suspense fallback={<Loading />}>
          <AccountDetails />
        </Suspense>
        // </PrivateRoute>
      }
    />
  </Route>
);

export default ViewSimulationRoute;
