// Package Imports
import { useTranslate } from '@refinedev/core';
import { Row, Col, Form, Button, Switch } from 'antd';
import { useEffect, useState } from 'react';
// Project Imports
import ComponentToRender from 'pages/unificationList/ComponentToRender';
import { useWatch } from 'antd/es/form/Form';
import statusDropdown from 'pages/unificationList/dropDownDummy';
import DropdownInput from 'components/dropdown/dropDownInput';
import { useSelect, useSimpleList } from '@refinedev/antd';
import apiEndpoint from 'core/common/apiEndPoints';
import getTimeLineDropdownList from 'core/utils/getTimeLineValue';
import getFilteFormattedDate from 'core/utils/getFilteFormattedDate';
import extractFrequencyName from 'pages/unificationList/utils/extractFrequencyName';

const Filter = ({
  formProps,
  setFilters,
  frequencyName,
  setFrequencyName,
  timelineType,
  setTimelineType,
  frequencyType,
  setFrequencyType,
}) => {
  const { form } = formProps;
  const t = useTranslate();
  const [showComponent, setShowComponent] = useState(null);
  const onReset = async () => {
    form?.resetFields();
    setFilters([], 'replace');
    setFrequencyName('');
    setShowComponent(null);
  };
  const programId = useWatch('program', {
    form,
    preserve: true,
  });
  const frequencyId = useWatch('frequency', {
    form,
    preserve: true,
  });
  const workflowId = useWatch('workflow', {
    form,
    preserve: true,
  });
  const date = useWatch('date', {
    form,
    preserve: true,
  });
  const programListProps = useSelect({
    dataProviderName: 'organizationDataProvider',
    resource: `${apiEndpoint.incentiveProgram}/?skip_user_permission=true`,
    optionLabel: 'iprogram_name',
    optionValue: 'unique_id',
    meta: {
      type: 'select',
    },
  });
  const programOptionList = (programListProps as any)?.selectProps?.options;

  const { listProps } = useSimpleList({
    resource: `${apiEndpoint.programConfigDetails}/?iprogram_id=${programId}`,
    dataProviderName: 'programDataProvider',
    queryOptions: { enabled: !!programId },
    pagination: {
      mode: 'off',
    },
  });
  const frequencyOptionList = (listProps?.dataSource as any)?.results;

  // get unique frequency name from frequency list
  const uniqueFrequencyIds = new Set();
  const uniqueFrequencyOptionList = programId
    ? frequencyOptionList?.filter((item) => {
        if (!uniqueFrequencyIds.has(item.frequency_id)) {
          uniqueFrequencyIds.add(item.frequency_id);
          return true;
        }
        return false;
      })
    : [];

  const { listProps: workflowData } = useSimpleList({
    resource: `${apiEndpoint.programConfigDetails}/?iprogram_id=${programId}&frequency_id=${frequencyId}`,
    dataProviderName: 'programDataProvider',
    queryOptions: { enabled: !!frequencyId && !!programId, cacheTime: 0 },
    pagination: {
      mode: 'off',
    },
  });
  const workflowList = (workflowData?.dataSource as any)?.results;

  const { listProps: timelineData } = useSimpleList({
    resource: `${apiEndpoint.paymentProcessTimeline}/?iprogram_id=${programId}&frequency_id=${frequencyId}&workflow_id=${workflowId}`,
    dataProviderName: 'paymentDataProvider',
    queryOptions: { enabled: !!frequencyId && !!programId && !!workflowId },
    pagination: {
      mode: 'off',
    },
  });
  const timelineResponse = (timelineData?.dataSource as any)?.results;

  const onSelectProgram = () => {
    setFrequencyName('');
    setShowComponent(null);
  };
  const onSelectFrequency = () => {
    setShowComponent(null);
  };

  useEffect(() => {
    if (programOptionList?.length === 1) {
      const value = programOptionList?.[0]?.value;
      form.setFieldValue('program', value);
      setFilters([
        {
          field: 'iprogram_id',
          operator: 'contains',
          value,
        },
      ]);
    }
  }, [programOptionList]);
  useEffect(() => {
    if (
      workflowList?.length === 1 &&
      workflowList[0]?.calculation_workflow_name === ''
    ) {
      const value = workflowList?.[0]?.workflow_id;
      form.setFieldValue('workflow', value);
      setFilters([
        {
          field: 'workflow_id',
          operator: 'contains',
          value,
        },
      ]);
    }
  }, [workflowList]);
  useEffect(() => {
    let frequencyNameData = extractFrequencyName(
      frequencyId,
      uniqueFrequencyOptionList,
      'name'
    );
    const frequencyTypeData = extractFrequencyName(
      frequencyId,
      uniqueFrequencyOptionList,
      'type'
    );
    frequencyNameData = frequencyNameData?.trim()?.toLowerCase();
    if (frequencyNameData !== frequencyName) {
      setFrequencyName(frequencyNameData);
      setFrequencyType(frequencyTypeData);
    }
    if (uniqueFrequencyOptionList?.length === 1) {
      const value = uniqueFrequencyOptionList?.[0]?.frequency_id;
      form.setFieldValue('frequency', value);
    }
  }, [uniqueFrequencyOptionList]);
  const timeLineDropdownList = frequencyId
    ? getTimeLineDropdownList(timelineResponse, frequencyName)
    : [];
  useEffect(() => {
    if (uniqueFrequencyOptionList?.length === 1 && frequencyId) {
      setFilters([
        {
          field: 'frequency_id',
          operator: 'contains',
          value: frequencyId,
        },
      ]);
    }
  }, [frequencyId]);
  useEffect(() => {
    if (timelineType && timeLineDropdownList?.length === 1) {
      form.setFieldValue('date', timeLineDropdownList?.[0]?.value);
    }
  }, [timeLineDropdownList]);
  useEffect(() => {
    if (timelineType && timeLineDropdownList?.length === 1 && date) {
      setFilters([
        {
          field: 'frequency_value',
          operator: 'contains',
          value: getFilteFormattedDate(frequencyName, date),
        },
      ]);
    }
  }, [date]);
  return (
    <Form
      layout="vertical"
      {...formProps}
      className="border px-4 bg-white pt-6 pb-4 mt-4 rounded-lg"
    >
      <Row gutter={16} className="mt-2">
        <Col xs={24} sm={24} lg={4}>
          <DropdownInput
            label={t('common.program')}
            name="program"
            message={t('errorMessages.selectProgramMsg')}
            placeholder={t('placeholder.program')}
            onChange={() => onSelectProgram()}
            optionList={programOptionList}
          />
        </Col>
        <Col xs={24} sm={24} lg={4}>
          <DropdownInput
            label={t('common.frequency')}
            name="frequency"
            placeholder={t('placeholder.frequency')}
            message={t('errorMessages.selectFrequencyMsg')}
            optionList={uniqueFrequencyOptionList?.map((item) => ({
              label: `${item?.frequency_name} `,
              value: item?.frequency_id,
            }))}
            disabled={!formProps.form?.getFieldValue('program')}
            onChange={() => onSelectFrequency()}
          />
        </Col>
        <Col xs={24} sm={24} lg={4}>
          <DropdownInput
            label={t('payoutHistory.workflow')}
            name="workflow"
            placeholder={t('payoutHistory.messages.workflow')}
            message={t('payoutHistory.messages.workflow')}
            optionList={workflowList
              ?.map((item) => {
                const options = [
                  {
                    label: `${item?.workflow_name} `,
                    value: item?.workflow_id,
                  },
                ];
                if (item?.calculation_workflow_id) {
                  options.push({
                    label: `${item?.workflow_name} (Calculation)`,
                    value: item?.calculation_workflow_id,
                  });
                }
                return options;
              })
              .flat()}
            disabled={!formProps.form?.getFieldValue('frequency')}
          />
        </Col>
        <Col xs={24} sm={24} lg={7}>
          <Switch
            checked={timelineType}
            onChange={(e) => {
              setTimelineType(e);
              form.setFieldValue('date', null);
            }}
            checkedChildren="Select"
            unCheckedChildren="Enter"
            className="z-10 w-[80px] absolute right-4"
          />
          {timelineType ? (
            <DropdownInput
              label={t('common.period')}
              name="date"
              optionList={timeLineDropdownList}
              disabled={!form?.getFieldValue('frequency')}
              className="relative"
            />
          ) : (
            <ComponentToRender
              frequencyType={frequencyType}
              setShowComponent={setShowComponent}
              showComponent={showComponent}
              disabled={!formProps.form?.getFieldValue('frequency')}
            />
          )}
        </Col>
        <Col xs={24} sm={24} lg={5}>
          <DropdownInput
            label={t('payoutHistory.payoutTableHeaders.status')}
            name="status"
            message={t('payoutHistory.messages.status')}
            placeholder={t('payoutHistory.messages.status')}
            optionList={statusDropdown}
          />
        </Col>
      </Row>
      <Row gutter={16} className="justify-end gap-4 mt-2">
        <Button onClick={onReset}>{t('buttons.reset')}</Button>
        <Button htmlType="submit" type="primary">
          {t('buttons.apply')}
        </Button>
      </Row>
    </Form>
  );
};
export default Filter;
