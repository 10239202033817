// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';
import openNotificationWithIcon from 'core/utils/notification';
import constants from 'core/constants/Constants';
import useLogger from 'core/utils/useLogger';

const directFileDownload = async (
  url: string,
  resetFields?: any,
  type?: string,
  payload?: any
) => {
  const logger = useLogger();
  try {
    let response;
    if (type === 'post') {
      response = await axiosInstance.post(url, payload, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    } else {
      response = await axiosInstance.get(url, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
      });
    }

    if (response.status === 200) {
      openNotificationWithIcon(
        constants.success,
        'Success Message',
        'File Downloaded Successfully'
      );
      if (resetFields) {
        resetFields();
      }
    } else if (response.status === 204) {
      openNotificationWithIcon(
        constants.success,
        'Success Message',
        response.headers['no-response']
      );
      if (resetFields) {
        resetFields();
      }
      return;
    } else {
      openNotificationWithIcon(
        constants.error,
        'Error Message',
        'Something Went Wrong'
      );
    }
    let fileName = 'downloaded_file';
    const contentDisposition = response.headers['content-disposition'];

    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    const fileType =
      response.headers['content-type'] || 'application/octet-stream';
    const blobData = new Blob([response.data], { type: fileType });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blobData);
    downloadLink.download = fileName;
    downloadLink.target = '_blank';
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  } catch (error) {
    if (error.response) {
      const errorMessage =
        error.response.data.message || 'Something Went Wrong';
      openNotificationWithIcon('error', 'Error Message', errorMessage);
    } else {
      openNotificationWithIcon(
        'error',
        'Error Message',
        'Something Went Wrong'
      );
    }
    logger('An error occurred:', error);
  }
};

export default directFileDownload;
