// Project Imports

import keycloak from 'keycloak/keyCloak';

const getIdentity = async () => {
  if (keycloak?.tokenParsed) {
    return {
      username: keycloak.tokenParsed.preferred_username,
      name: keycloak.tokenParsed.given_name,
      roles: keycloak?.tokenParsed?.realm_access?.roles,
      id: keycloak?.tokenParsed?.sid,
    };
  }

  return null;
};

export default getIdentity;
