
export default function ConvertStringFloatInDecimal(value) {
  const cleanedValue = value.replace(',', '');
  const parsedValue = parseFloat(cleanedValue);
  if (!Number.isNaN(parsedValue)) {
    return parsedValue?.toLocaleString('en-IN', {
      maximumFractionDigits: 2,
    })
  }
  return value;
}
