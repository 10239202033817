// Package Imports
import dayjs from 'dayjs';

// Project Imports
import constants from 'core/constants/Constants';

/* eslint-disable no-case-declarations */
export function getTimeLineValue(freqType, freqValue) {
  const splittedDate = freqValue?.split(' - ');
  const startDateValue = splittedDate[0]?.trim();
  const endDateValue = splittedDate[1]?.trim();
  const startMonth = dayjs(startDateValue)?.format(constants?.mmmYYYY);
  const endMonth = dayjs(endDateValue)?.format(constants?.mmmYYYY);
  switch (freqType) {
    case 'monthly':
      const monthlyDate = dayjs(startDateValue, constants.dateFormatWithHyphen);
      const month = monthlyDate?.format(constants.monthFormat)?.toUpperCase();
      const monthYear = monthlyDate?.year();
      return {
        label: `${month}, ${monthYear}`,
        value: month?.toLowerCase(),
      };
    case 'weekly':
      const startWeek = dayjs(startDateValue).format(constants.mmmDYYYY);
      const endWeek = dayjs(endDateValue).format(constants.mmmDYYYY);

      return { label: `${startWeek} - ${endWeek}`, value: freqValue };
    case 'yearly':
      const year = startDateValue?.split('-')?.[0]?.trim();
      return { label: year, value: year };
    case 'half yearly':
      return { label: `${startMonth} - ${endMonth}`, value: freqValue };
    case 'daily':
      const date = dayjs(freqValue)?.format(constants?.mmmDDYYYY);
      return { label: date, value: freqValue };
    case 'quarterly':
      return { label: `${startMonth} - ${endMonth}`, value: freqValue };
    default:
      return null;
  }
}

export default function getTimeLineDropdownList(
  timelineResponse,
  frequencyName
) {
  if (timelineResponse && frequencyName) {
    const timeLineDropdownList = timelineResponse?.map((item) =>
      getTimeLineValue(frequencyName, item?.frequency_value)
    );
    return timeLineDropdownList;
  }
  return [];
}
