// Package Imports
import React, { useState, useRef, useEffect } from 'react'
import { Form, Modal } from 'antd';
// Project Imports
import A8Utils from '../../../utils/A8Utils';
import TextArea from 'antd/es/input/TextArea';

const PrimaryButtonModal = (props) => {
    const { component, showPositiveModal, setShowPositiveModal, modalSubmitHandler } = props;

    const [buttonDisabled, setButtonDisabled] = useState(true)

    const FormItem = Form.Item;
    const [form] = Form.useForm();
    const textAreaRef = useRef(null);

    function handleOk() {
        form.validateFields()
            .then((values) => {
                modalSubmitHandler(values)
                setShowPositiveModal(false)
                resetForm()
            })
            .catch((errorInfo) => { });
    }

    function resetForm() {
        setShowPositiveModal(false)
        setButtonDisabled(true)
        form.resetFields()
    }
    useEffect(() => {
        if (showPositiveModal && textAreaRef.current) {
          setTimeout(() => {
            if (textAreaRef.current) {
              textAreaRef.current.focus();
            }
          }, 0);
        }
      }, [showPositiveModal]);

    return (
        <>
            <Modal title="Approve" okText="Submit"  open={showPositiveModal} onOk={handleOk} onCancel={resetForm} okButtonProps={{className:'btn-positive', disabled: buttonDisabled }} >
                <Form
                    form={form}
                    onFieldsChange={() => {
                        let isFormRemaining = form.getFieldsError().some((field) => field.errors.length > 0)
                        setButtonDisabled(isFormRemaining)
                    }}
                >
                    {
                        component?.positiveActionButtonComponentsList?.map((item, index) => {
                            switch (item.compoentFieldType) {
                                case A8Utils.formBuilderFieldNames.text:
                                    return (
                                        <>
                                            <div key={index} className=''>
                                                <label className="">{item.componentLabel} <span className='asterisk'>*</span></label>
                                                <FormItem
                                                    name={item.compoentFormNameParameter}
                                                    rules={[
                                                        { required: true, message: A8Utils.labels.commentsValidation },
                                                    ]}
                                                >
                                                    <TextArea
                                                        ref={textAreaRef}
                                                        rows={4}
                                                        placeholder={`${A8Utils.labels.enter} ${item.componentLabel}`}
                                                        maxLength={200}
                                                    />
                                                </FormItem>
                                            </div>
                                        </>
                                    )
                                    break;

                                default:
                                    break;
                            }
                        })
                    }
                </Form>
            </Modal>
        </>
    )
}

export default PrimaryButtonModal