// Package Imports
import { useTranslate } from '@refinedev/core';
import { useEffect } from 'react';
import { DatePicker, Form } from 'antd';

// Project Imports
import DropdownInput from 'components/dropdown/dropDownInput';
import {
  halfYearlyList,
  monthlyList,
  quarterlyList,
  yearsList,
} from 'pages/payoutInitialization/dropdownlist';
import constants from 'core/constants/Constants';
import DateInput from 'components/date/dateInput';

const ComponentToRender = ({
  frequencyType,
  setShowComponent,
  showComponent,
  disabled,
}) => {
  const t = useTranslate();
  const { RangePicker } = DatePicker;

  const validateDateRange = (rule, value, callback) => {
    if (value && value.length === 2) {
      const startDate = value[0];
      const endDate = value[1];

      const differenceInDays = Math.abs(endDate.diff(startDate, 'days'));
      if (differenceInDays !== 7) {
        callback(t('pages.payoutInitialization.messages.rangeDateValidMsg'));
      }
    }
    callback();
  };

  const getComponentByFrequency = (frequency) => {
    if (frequency === 'month') {
      return (
        <DropdownInput
          label={t('common.month')}
          name="date"
          message={t('payoutInitialization.messages.monthly')}
          disabled={disabled}
          optionList={monthlyList}
          placeholder={t('placeholder.month')}
        />
      );
    }

    if (frequency === 'week') {
      return (
        <div className="relative custom-border">
          <label className="floating-label">{t('common.date')}</label>
          <Form.Item
            rules={[
              {
                required: false,
                message: t('pages.payoutInitialization.messages.rangeDate'),
              },
              { validator: validateDateRange },
            ]}
            name="date"
          >
            <RangePicker
              className="payout-history-rangepicker"
              disabled={disabled}
            />
          </Form.Item>
        </div>
      );
    }

    if (frequency === 'daily') {
      return (
        <DateInput
          label={t('common.date')}
          name="date"
          message={t('pages.payoutInitialization.messages.dateValidMsg')}
          placeholder={constants.dateFormatWithHyphen}
          format={constants.dateFormatWithHyphen}
          disabled={disabled}
        />
      );
    }

    if (frequency === 'quarter') {
      return (
        <DropdownInput
          label={t('common.quarter')}
          name="date"
          message={t('payoutInitialization.messages.quarterly')}
          disabled={disabled}
          optionList={quarterlyList}
          placeholder={t('placeholder.quarter')}
        />
      );
    }

    if (frequency === 'year') {
      return (
        <DropdownInput
          label={t('common.year')}
          name="date"
          message={t('payoutInitialization.messages.yearly')}
          disabled={disabled}
          optionList={yearsList}
          placeholder={t('placeholder.year')}
        />
      );
    }

    if (frequency === 'half year') {
      return (
        <DropdownInput
          label={t('common.halfYear')}
          name="date"
          message={t('payoutInitialization.messages.halfYearly')}
          disabled={disabled}
          optionList={halfYearlyList}
          placeholder={t('placeholder.halfYear')}
        />
      );
    }

    return (
      <DateInput
        label={t('common.date')}
        name="date"
        message={t('pages.payoutInitialization.messages.dateValidMsg')}
        placeholder={constants.dateFormatWithHyphen}
        format={constants.dateFormatWithHyphen}
        disabled={disabled}
      />
    );
  };

  useEffect(() => {
    // if (frequencyType) {
    const component = getComponentByFrequency(frequencyType);
    setShowComponent(component);
    // } else {
    //   setShowComponent(null);
    // }
  }, [frequencyType, disabled]);
  return <div className="payout-history-datepicker">{showComponent}</div>;
};

export default ComponentToRender;
