// Package Imports
import {
  BaseRecord,
  CrudFilters,
  HttpError,
  useTranslate,
} from '@refinedev/core';
import { useTable } from '@refinedev/antd';
import { Card, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
// Project Imports
import apiEndpoint from 'core/common/apiEndPoints';
import { FilterVariables, IPost } from 'pages/unificationList/interface';
import scrollToTop from 'core/utils/scrollToTop';
import UnificationHistoryTable from 'pages/unificationList/unificationHistoryTable';
import Filter from 'pages/unificationList/filter';
import getFilteFormattedDate from 'core/utils/getFilteFormattedDate';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import useLogger from 'core/utils/useLogger';

export const UnificationHistoryView = () => {
  const t = useTranslate();
  const logger = useLogger();
  const { initiateEvent } = useTokenContext();
  const [frequencyName, setFrequencyName] = useState<string>();
  const [frequencyType, setFrequencyType] = useState<string>();
  const [timelineType, setTimelineType] = useState<boolean>(true);
  const { tableQueryResult, tableProps, searchFormProps, setFilters } =
    useTable<IPost, HttpError, FilterVariables>({
      resource: apiEndpoint.unificationRunMaster,
      dataProviderName: 'payoutProvider',
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { program, frequency, date, status, workflow } = params;
        filters.push(
          {
            field: 'iprogram_id',
            operator: 'contains',
            value: program,
          },
          {
            field: 'frequency_id',
            operator: 'contains',
            value: frequency,
          },
          {
            field: 'workflow_id',
            operator: 'contains',
            value: workflow,
          },
          {
            field: 'date',
            operator: 'contains',
            value: date
              ? getFilteFormattedDate(frequencyName, date, timelineType)
              : null,
          },
          {
            field: 'status',
            operator: 'contains',
            value: status,
          }
        );
        return filters;
      },
      syncWithLocation: false,
    });

  const { refetch } = tableQueryResult;
  const fetchData = async () => {
    try {
      await refetch();
    } catch (error) {
      logger('Error refetching data:', error);
    }
  };
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchData();
  }, [initiateEvent]);

  const unificationHistoryTableData = (
    tableQueryResult?.data?.data as BaseRecord
  )?.results;

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <>
      <Card className="card-style flex items-center">
        <h4 className="card-title">{t('unificationList.title')}</h4>
      </Card>
      <Row gutter={[16, 16]} className="mt-4">
        <Col lg={24} xs={24}>
          <Filter
            setFilters={setFilters}
            formProps={searchFormProps}
            setFrequencyName={setFrequencyName}
            frequencyName={frequencyName}
            timelineType={timelineType}
            setTimelineType={setTimelineType}
            frequencyType={frequencyType}
            setFrequencyType={setFrequencyType}
          />
        </Col>
        <Col lg={24} xs={24}>
          <UnificationHistoryTable
            tableProps={tableProps}
            unificationHistoryTableData={unificationHistoryTableData}
          />
        </Col>
      </Row>
    </>
  );
};
export default UnificationHistoryView;
