// package imports
import { Show, useSimpleList } from '@refinedev/antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Empty } from 'antd';
// project imports
import apiEndpoint from 'core/common/apiEndPoints';

import directFileDownload from 'core/utils/directFileDownload';
import { useTranslate } from '@refinedev/core';
import TreeNode from 'pages/unificationList/unificationTreeNode';

const UnificationHistoryShow = () => {
  const { id } = useParams();
  const t = useTranslate();
  const { listProps } = useSimpleList({
    resource: `${apiEndpoint.unificationHistoryTree}/${id}`,
    dataProviderName: 'unifiedDataProvider',
    queryOptions: {
      enabled: !!id,
    },
    pagination: {
      mode: 'off',
    },
    syncWithLocation: false,
  });

  const onFinishHandler = async (node) => {
    const startDate = moment(node?.meta?.startDate).format('YYYY-MM-DD');
    const endDate = moment(node?.meta?.endDate).format('YYYY-MM-DD');

    let apiUrl;
    if (
      node.parent_source_type === 'unified' ||
      node.source_type === 'unified'
    ) {
      apiUrl = `${apiEndpoint.downloadUnifiedFile}/?source_type=${node.source_type}&source_id=${node.unique_id}&start_date=${startDate}&end_date=${endDate}`;
    } else {
      apiUrl = `${apiEndpoint.unificationDownload}/?source_type=${node.source_type}&source_id=${node.unique_id}&start_date=${startDate}&end_date=${endDate}`;
    }
    await directFileDownload(apiUrl);
  };

  const unificationStructureData = listProps?.dataSource;

  return (
    <Show
      breadcrumb={false}
      headerButtons={<> </>}
      title={t('pages.unificationTreeShowTitle.title')}
      canEdit={false}
    >
      <div className="tree">
        {unificationStructureData ? (
          <TreeNode
            node={unificationStructureData}
            onFinishHandler={onFinishHandler}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </div>
    </Show>
  );
};

export default UnificationHistoryShow;
