// package imports
import { useTranslate } from '@refinedev/core';
import { Button, List, Space, Table } from 'antd';
import moment from 'moment';
// project imports
import constants from 'core/constants/Constants';
import { CloseOutlined, DownloadOutlined } from '@ant-design/icons';
import directFileDownload from 'core/utils/directFileDownload';
import apiEndpoint from 'core/common/apiEndPoints';
import resourceName from 'core/common/resourceName';
import { DeleteButton, ShowButton } from '@refinedev/antd';

import openNotificationWithIcon from 'core/utils/notification';
import replacePipe from 'core/utils/replacePipe';
import dayjs from 'dayjs';
import { Images } from 'core/common/imagePath';
import { useTokenContext } from 'contexts/resource/ResourceContext';
// import { Fragment } from 'react/jsx-runtime';

const UnificationHistoryTable = ({
  tableProps,
  unificationHistoryTableData,
}) => {
  const t = useTranslate();
  const { notificationMessages } = useTokenContext();
  const createRenderDate = (position) => (_, data) => {
    const dates = data?.date?.split(' - ');
    const date = moment(dates?.[position]);
    return date.format(constants.dateFormat);
  };

  const renderStartDate = createRenderDate(0);
  const renderEndDate = createRenderDate(1);

  function formatStatus(status) {
    return status
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  function renderShowButtonUrl(row, id) {
    const startDate = moment(row.start_date).format('YYYY-MM-DD');
    const endDate = moment(row.end_date).format('YYYY-MM-DD');
    const columnDate = { startDate, endDate };

    return (
      <ShowButton
        hideText
        resource={resourceName.unificationHistory}
        meta={columnDate}
        recordItemId={id}
        className="a8-edit-button"
      />
    );
  }

  function renderDeleteButton(record) {
    return (
      <DeleteButton
        className="unified-cancel-status a8-delete-button"
        hideText
        confirmOkText="Yes"
        recordItemId={record?.unique_id}
        icon={<CloseOutlined className="text-red-600 text-[20px]" />}
        resource={apiEndpoint.unificationRunMaster}
        dataProviderName="payoutProvider"
        successNotification={false}
        onSuccess={(data: any) => {
          const message =
            data?.data?.message || t(`notifications.deleteSuccess`);
          openNotificationWithIcon(
            constants.success,
            t('notifications.mutationSuccess'),
            message
          );
        }}
        errorNotification={(data: any) => {
          const message =
            replacePipe(data?.message) || t('notifications.deleteError');
          return {
            message: 'Error Message',
            description: message,
            type: 'error',
          };
        }}
      />
    );
  }

  return (
    <List>
      <Table
        {...tableProps}
        className="mt-3"
        bordered
        dataSource={unificationHistoryTableData}
        rowKey="id"
        scroll={{ x: 767 }}
      >
        <Table.Column
          dataIndex="iprogram_name"
          title={t('payoutHistory.payoutTableHeaders.program')}
        />
        <Table.Column
          dataIndex="frequency_name"
          className="capitalize"
          title={t('payoutHistory.payoutTableHeaders.frequency')}
        />
        <Table.Column
          dataIndex="workflow_name"
          title={t('payoutHistory.payoutTableHeaders.workflow')}
        />
        <Table.Column
          dataIndex="unified_rule_plan_mapping"
          title={t('payoutHistory.payoutTableHeaders.unifiedSourceName')}
          render={(
            _,
            row: {
              unified_rule_plan_mapping: Array<{
                unified_source_master_id: string;
                unified_source_master_name: string;
              }>;
              status?: string;
              is_payout_calculation_done?: boolean;
              batch_id?: string;
              is_live_calculation?: boolean;
            }
          ) => (
            <table style={{ borderCollapse: 'collapse' }}>
              {row.unified_rule_plan_mapping?.map((item, index) => (
                <tr
                  key={item.unified_source_master_id}
                  className={`border-solid border-gray ${index !== 0 ? 'border-t' : 'border-t-0'} border-x-0 border-b-0`}
                >
                  <td>
                    <span style={{ display: 'block' }}>
                      {item?.unified_source_master_name}
                    </span>
                  </td>
                  <td>
                    {row?.status?.toLowerCase() === 'unification_done' ? (
                      <Button
                        icon={<DownloadOutlined />}
                        onClick={() =>
                          directFileDownload(
                            `${apiEndpoint.downloadUnifiedFile}/?unified_source_master_id_list=${item.unified_source_master_id}&batch_id=${(row as any)?.batch_id}&is_live_calculation=${(row as any)?.is_live_calculation}`
                          )
                        }
                      />
                    ) : null}
                    {row?.status?.toLowerCase() !== 'unification_done' &&
                    row?.status?.toLowerCase() !== 'unification_cancelled' ? (
                      <img
                        width={50}
                        height={50}
                        src={Images.LoadingGif}
                        alt="Loading..."
                      />
                    ) : null}
                  </td>
                  {row?.status?.toLowerCase() === 'unification_done' ? (
                    <td align="right">
                      {renderShowButtonUrl(row, item.unified_source_master_id)}
                    </td>
                  ) : null}

                  {/* </div> */}
                </tr>
              ))}
            </table>
          )}
          width={250}
        />
        <Table.Column
          dataIndex="start_date"
          title={t('payoutHistory.payoutTableHeaders.startDate')}
          render={renderStartDate}
        />
        <Table.Column
          dataIndex="end_date"
          title={t('payoutHistory.payoutTableHeaders.endDate')}
          render={renderEndDate}
        />
        <Table.Column
          dataIndex="live_calculation_triggered_at"
          title={t('payoutHistory.payoutTableHeaders.startedAt')}
          render={(_, data: any) =>
            data.live_calculation_triggered_at
              ? dayjs(data.live_calculation_triggered_at).format(
                  constants.dateAndTimeFormat
                )
              : '-'
          }
        />
        <Table.Column
          dataIndex="live_calculation_ended_at"
          title={t('payoutHistory.payoutTableHeaders.endedAt')}
          render={(_, data: any) =>
            data.live_calculation_ended_at
              ? dayjs(data.live_calculation_ended_at).format(
                  constants.dateAndTimeFormat
                )
              : '-'
          }
        />
        <Table.Column
          dataIndex="updated_by_user"
          title={t('common.executedBy')}
        />
        <Table.Column
          dataIndex="status"
          title={t('payoutHistory.payoutTableHeaders.status')}
          render={(status) => formatStatus(status)}
        />

        <Table.Column
          dataIndex="action"
          title={t('table.actions')}
          render={(_, row: any) => {
            const notifications = notificationMessages.filter(
              (msg) => msg.sourceId === row?.unique_id
            );
            const allSuccess = notifications.every(
              (notification) => notification.status === 'success'
            );
            const isLoading = notifications.length > 0 && !allSuccess;

            const status = row?.status?.toLowerCase();

            return (
              <Space size="large">
                {isLoading ||
                (status !== 'unification_done' &&
                  status !== 'unification_cancelled') ? (
                  <img
                    width={50}
                    height={50}
                    src={Images.LoadingGif}
                    alt="Loading..."
                  />
                ) : (
                  status === 'unification_done' &&
                  !row?.is_payout_calculation_done &&
                  renderDeleteButton(row)
                )}
              </Space>
            );
          }}
          width={50}
        />
      </Table>
    </List>
  );
};
export default UnificationHistoryTable;
